.pricing-section {
    display: flex;
    justify-content: space-between;
    align-items: stretch; 
    padding: 40px 20px; 
    max-width: 1200px; 
    margin: 0 auto; 
    gap: 60px; 
}

.pricing-card {
    background: linear-gradient(112deg, #89AAFF, #170AAC);
    color: white;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    flex: 1; 
    min-width: 348px; 
    max-width: 500px; 
    text-align: left;
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    position: relative;
}
.pricing-card:before{
    content: "";
    width: calc(100% - 48px);
    height: 12px;
    position: absolute;
    bottom: -13px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%);
    border-radius: 0 0 12px 12px;
    background: #DAE2FF;
}
.pricing-card h1 {
    font-size: 48px;
    margin: 42px 0;
}

.pricing-card h2 {
    margin-bottom: 15px;
}

.pricing-card h1 span {
    font-size: 20px;
    color: #ffffff99;
}

.pricing-card ul {
    list-style: none;
    padding: 0;
    flex-grow: 1; 
}

.pricing-card ul li {
    font-size: 16px;
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.pricing-card ul li .info-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.pricing-card button {
    background-color: #fff;
    color: #0066ff;
    border: none;
    padding: 12px 24px;
    border-radius: 12px;
    font-size: 1rem;
    cursor: pointer;
    margin: 40px 0 20px;
    font-weight: 600;
}

.pricing-card button:hover {
    background-color: #f0f0f0;
}

/* Media Queries */
@media (max-width: 1024px) {
    .pricing-section {
        gap: 40px;
    }

    .pricing-card {
        padding: 25px;
    }

    .pricing-card h1 {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .pricing-section {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin: 10px;
        padding: 0;
    }

    .pricing-card {
        margin-bottom: 20px;
        max-width: 100%; /* Adjust for smaller screens */
    }

    .pricing-card h1 {
        font-size: 36px;
    }

    .pricing-card ul li {
        font-size: 14px;
    }

    .pricing-card button {
        font-size: 0.9rem;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .pricing-section {
        padding: 10px;
        gap: 10px;
    }

    .pricing-card {
        padding: 20px;
        max-width: 95%;
        min-width: 290px;
    }

    .pricing-card h1 {
        font-size: 32px;
        margin: 30px 0;
    }

    .pricing-card h2 {
        font-size: 18px;
    }

    .pricing-card ul li {
        font-size: 12px;
        padding: 5px 0;
    }

    .pricing-card button {
        font-size: 0.8rem;
        padding: 8px 16px;
        margin: 20px 0 10px;
    }
}
