.features-container {
  max-width: 1000px;
  margin: 0 auto 120px;
  padding: 0px 20px 20px;
  margin-bottom: 90px;
  text-align: center;
  }
  
  .features-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.3;
  }
  .small-heading {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
  .features-description {
    font-size: 1rem;
    color: #7a7a7a;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.4;
    margin-bottom: 50px;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
  }
  
  .feature-card {
    background: #fff;
    border-radius: 22px;
    box-shadow: 0 4px 16px rgb(7 28 53 / 11%);
    padding: 20px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
  }
  
  .feature-icon {
    width: 60px;
    height: auto;
    margin-bottom: 15px;
  }
  
  .feature-heading {
    font-size: 1rem;
    color: #333;
    text-align: left;
    margin-bottom: 10px;
  }

  .feature-description {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.3;
    text-align: left;
  }
  
  .features-column:nth-child(1) .feature-card {
    min-width: 280px; 
  }
  
  .features-column:nth-child(2) .feature-card {
    min-width: 220px; 
  }
  
  .features-column:nth-child(3) .feature-card {
    min-width: 280px;
  }
  
  .features-column:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .features-grid > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 820px) and (min-width: 769px) {
    .features-grid {
      display: grid;
      grid-template-columns: 1fr; 
      gap: 20px;
      justify-items: center;
    }
  
    .feature-card {
      width: 100%; 
      max-width: 500px; 
      margin: 0 auto; 
    }

    .feature-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .features-heading {
      font-size: 1.6rem;
    }
  
    .feature-heading {
      font-size: 1.1rem;
    }
  
    .feature-description {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    .features-grid {
      grid-template-columns: 1fr;
    }
    
    .feature-card {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
    .features-heading{
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .features-heading{
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .features-description{
      font-size: 0.9rem;
    }
}