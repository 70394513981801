.container {
max-width: 1400px;
}

.footer {
  text-align: left;
  background-color: #f9f2f5;
  position: relative;
}

.footer a {
  color: black;
  text-decoration: none;
}

.apply-for-invite {
  text-align: center;
  padding: 100px 0 0px 0;
  position: relative;
  z-index: 1;
}

.footer-bg-block {
  margin-top: -110px;
}

.footer-content-inner {
  padding: 85px 0 30px 0;
}

.copyright-icon-block {
  padding-bottom: 40px;
}

.footer-top {
  background-color: #f8f9fb;
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-bg-block img.footer-bg-img {
  width: 100%;
  border-radius: 0;
}

.footer-right div {
  height: 100%;
}

.footer-right ul {
  height: 100%;
}

.footer-right ul li {
  padding: 10px 0;
  text-decoration: none;
}

.social-icons-ul {
  display: flex;
  justify-content: flex-end;
  list-style: none; 
  padding: 0;
  margin: 0;
}

.social-icons-ul .social-icons-li {
  background: #213047;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: 0.3s;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.separation-line {
  border-bottom: 1px solid gray;
}

.apply-for-invite-txt p {
  margin-bottom: 30px;
}

.footer-content hr.separation-line {
  margin: 30px 0;
}

.copyright p {
  margin: 0;
  font-size: 0.9rem;
}

.copyright-icon-block .row {
  align-items: center;
}

.social-icons-ul li a {
  display: block;
}

.social-icons-ul li ~ li {
  padding-left: 15px;
}

.footer-right a:hover {
  color: #df0071;
}

.footer-wrapper.mar-t-8 {
  padding: 0;
}

.footer__wrapper {
  background-color: #ebf2fc;
}

img.footer-members-img {
  height: 36px;
}

.footer-logo {
  width: 140px;
  margin-bottom: 20px;
}

.members-ul {
  display: flex;
  list-style: none; 
  padding: 0; 
  margin: 0;
  flex-wrap: wrap;
}

.members-li {
  margin-right: 20px;
}

.footer-end {
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid #334960 !important;
}

.footer-items {
  padding-bottom: 40px !important;
}

.footer__banner--inner {
  text-align: center !important;
  padding: 100px 0;
  position: relative !important;
}

.text-link {
  color: #334960;
}

.footer__banner--heading p {
  margin: 0 0 3% 0;
}

.main_list {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap;
  gap: 20px;
}

.sub_list {
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.list {
  padding-bottom: 20px;
  font-family: Arial, sans-serif; 
  font-size: 16px; 
}

.footer-inner {
  padding-top: 50px;
}

.footer__banner--wrapper img {
  position: absolute;
  bottom: -36px;
}

.footer__banner--wrapper {
  position: relative !important;
}

.footer-background {
  width: 100%;
  height: auto;
}

.partner-second-row {
  margin-top: 30px;
}

.members-li img {
  height: 32px;
  width: 130px;
}

.fa {
  font-size: medium !important;
}

.partner-second-row {
  margin-top: 30px;
}

img.powerBi {
  width: 107px;
}

.footer-left p{
  margin-bottom: 20px;
  font-size: 0.9rem;
}

@media (min-width: 2560px) {
  .footer__banner--wrapper img {
    bottom: -50px !important;
  }
}

@media (min-width: 1920px) {
  .footer__banner--wrapper img {
    bottom: -37px !important;
  }
}

@media (min-width: 1440px) {
  .footer__banner--wrapper img {
    bottom: -30px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .footer__banner--wrapper img {
    bottom: -20px;
  }
}

@media (min-width: 1536px) {
  .footer__banner--wrapper img {
    bottom: -30px !important;
  }
}

@media (min-width: 1853px) {
  .footer__banner--wrapper img {
    bottom: -35px !important;
  }
}

@media (min-width: 1600px) {
  .footer__banner--wrapper img {
    bottom: -31px;
  }
}

@media (min-width: 1280px) {
  .footer__banner--wrapper img {
    bottom: -26px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footer__banner--wrapper img {
    bottom: -15px;
  }

  .social-icons-ul {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .footer-left {
    width: 100%;
  }

  .footer-bg-block {
    margin-top: -20px;
  }

  .copyright-icon-block {
    padding-bottom: 30px;
  }

  .footer-content hr.separation-line {
    margin: 20px 0;
  }

  .footer-content-inner {
    padding: 40px 0 10px 0;
  }

  .apply-for-invite-txt {
    padding: 0 15px;
  }

  .main_list {
    flex-direction: column;
    margin-top: 20px;
    justify-content: inherit;
  }

  .footer-end {
    padding-top: 20px;
  }

  .footer-items {
    padding-bottom: 30px !important;
  }

  .footer__banner--wrapper img {
    margin-top: 0;
    display: none;
  }

  .footer__banner--inner {
    padding: 50px 0;
  }

  .social-icons-ul {
    justify-content: center;
    margin-right: 0;
  }

  .row .col:last-child {
    margin-left: 0 !important;
  }

  .col-sm-9.copyright {
    padding-bottom: 10px;
  }

  .sub_list {
    margin: 0;
    justify-content: inherit;
  }
  
  .list {
    padding-bottom: 10px;
  }
}

@media (max-width: 425px) {
  .members-ul {
    flex-direction: column;
  }

  .members-li {
    margin-top: 25px;
  }

  .partner-second-row {
    margin-top: 0;
  }
}
