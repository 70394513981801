.recommendations-container {
  max-width: 1200px;
  margin: 0 auto 120px;
  padding: 0 20px;
  margin-bottom: 120px;
  }

  .recommendations-section {
    padding: 20px 0;
    text-align: center;
  }
  
  .recommendations-header {
    margin-bottom: 40px;
  }
  
  .recommendations-small-heading {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .recommendations-large-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.3;
    text-align: center;
  }
  
  .recommendations-description {
    font-size: 1rem;
    color: #7a7a7a;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.4;
    margin-bottom: 50px;
    text-align: center;
  }
  
  .recommendations-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .recommendation-card {
    background: #FAFCFF;
    border: 1px solid #DCE4FF;
    border-radius: 22px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  
  .recommendation-card-content {
    display: flex;
    margin-bottom: 15px;
    border-bottom: 1px solid #3E74FF;
    align-items: center;
  }
  
  .recommendation-icon {
    width: 40px;
    height: auto;
    margin-right: 15px;
  }
  
  .recommendation-heading {
    font-size: 1rem;
    color: #333;
    text-align: left;
  }
  
  .recommendation-text {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.3;
    text-align: left;
  }
  
  .recommendation-image {
    width: 100%;
    height: auto;
    margin-top: 20px;
    align-self: center;
  }
  
  .recommendation-image-row1 {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .recommendations-grid-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px; 
  }
  
  .large-card {
    min-width: 250px;
  }
  
  .small-card {
    min-width: 220px;
  }
  .recommendation-image,
.recommendation-image-row1 {
    width: 100%;
    height: auto;
    margin-top: 20px;
    align-self: center;
    transition: transform 0.3s ease; /* Smooth transition for image zoom */
}

.recommendation-image:hover,
.recommendation-image-row1:hover {
    transform: scale(1.04); /* Zoom effect on image hover */
}

  @media (max-width: 768px) {
    .recommendations-grid,
    .recommendations-grid-2 {
      grid-template-columns: 1fr;
    }
  
    .recommendation-card {
      max-width: 500px;
      margin: 0 auto;
    }
    .recommendations-large-heading{
      font-size: 1.8rem;
    }
  }

  @media (max-width: 480px) {
    .recommendations-small-heading {
        font-size: 0.9rem; 
    }
    .recommendations-large-heading {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .content-heading{
        font-size: 1rem;
       margin-bottom: 18px;
    }
    .content-paragraph{
        font-size: 0.9rem;
       margin-bottom: 18px;
    }
    .recommendation-heading {
      font-size: 0.8rem;
  }
  .recommendations-container {
    margin-bottom: 20px;
    }
  }