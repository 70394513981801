.visibility-section {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 120px;
}
.visibility-header {
    margin-bottom: 40px;
}

.visibility-small-heading {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.visibility-big-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 50px;
    line-height: 1.3;
}

.visibility-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.content-row {
    display: flex;
    align-items: center;
    gap: 20px; 
}

.content-image, .content-text {
    flex: 1;
}

.content-image img {
    width: 85%;
    height: auto;
    border-radius: 8px;
}

.content-text {
    text-align: left;
}

.cta-button {
    display: block;
    margin: 10px 0;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.visibility-button {
    background: linear-gradient(135deg, #F4F0FF, #E8F7FF);
    color: #4b4a4a;
    border-radius: 22px;
    padding: 8px 20px;
    border: none;
    font-size: 0.9rem;
    margin: 0 30px 20px;
}

.discover-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 12px;
    background-color: #ffffff;
    border: 1px solid #0D5C9C;
    color: #0D5C9C;
    font-size: large;
}

.content-heading {
    font-size: 1.5rem;
    color: #333;
    line-height: 1.3;
    margin: 10px 30px 30px;
}

.content-paragraph {
    font-size: 1rem;
    color: #7a7a7a;
    line-height: 1.4;
    margin: 10px 30px 30px;
}

.reverse {
    flex-direction: row-reverse;
}

@media (max-width: 768px) {
    .content-row {
        flex-direction: column;
        align-items: center;
    }

    .content-image, .content-text {
        width: 100%;
    }

    .content-text {
        text-align: center;
    }
    .visibility-big-heading{
        font-size: 1.8rem;
    }
}

@media (max-width: 480px) {
    .visibility-small-heading {
        font-size: 0.9rem; 
    }
    .visibility-big-heading{
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .content-heading{
        font-size: 1.2rem;
       margin-bottom: 18px;
    }
    .content-paragraph{
        font-size: 0.9rem;
       margin-bottom: 18px;
       margin: 0px 0px 0px;
    }
    .discover-button {
        font-size: 0.8rem;
    }
    .visibility-section{
        margin-bottom: 30px;
    }
  }
