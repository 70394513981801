.explore-content {
    width: 75%;
    margin: auto;
    text-align: center;
}
.explore-content h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
}
.explore-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
}
.explore-now-btn{
    font-size: 1.2rem;
    padding: 15px 30px;
    border-radius: 12px;
    color: #2D28BC;
    background-color: #ffffff;
    border-color: #2D28BC;
}
section.explore-section {
    margin-bottom: 120px;
}

@media (max-width:768px){
    .explore-content h2{
        font-size: 1.8rem;
    }
    section.explore-section {
        margin-bottom: 70px;
    }
}

@media (max-width:480px){
    .explore-content h2{
        font-size: 1.4rem;
    }
    .explore-content p{
        font-size: 0.8rem;
        margin-bottom: 28px;
    }
    .explore-now-btn {
        font-size: 0.9rem;
        padding: 10px 22px;
    }
}