* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.banner-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0px 20px;
  margin-bottom: 90px;
  text-align: center;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/Banner/BannerBGImage.svg") no-repeat center center / cover;
  opacity: 0.8; 
  z-index: -1; 
}

.banner-content {
  z-index: 2;
  margin: 40px 30px 0
}

.banner-icon {
  width: 70px;
  margin-bottom: 20px;
}

.banner-heading {
  font-size: 4rem;
  color: #252550;
  margin-bottom: 20px !important;
  line-height: 1.3;
  font-weight: bold;
}

/* Paragraph */
.banner-paragraph {
  font-size: 1rem;
  color: #7a7a7a;
  margin-bottom: 50px !important;
  max-width: 790px;
  line-height: 1.4;
}

.book-demo-button {
  padding: 12px 26px;
  cursor: pointer;
  border-radius: 12px;
  background-color: #0D5C9C;
  border: none;
  color: white;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.book-demo-button:after{
  animation: button 1s ease 1s infinite both running;
}
.book-demo-button:hover {
  background-color: #084b72;
  transform: scale(1.05); 
}

.dashboard-image-container {
  display: flex;
  justify-content: center;
  margin: 40px 20px 0;
  max-width: 1200px;
}

.dashboard-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}

.video-outer-wrapper {
  padding: 4px; 
  border: 2px solid #AFCDF7; 
  border-radius: 20px;
  display: inline-block;
}

.video-inner-wrapper {
  padding: 8px;
  border: 10px solid #AFCDF7;
  border-radius: 20px;
}

.banner + .trusted-by {
  margin-top: 60px;
}

@media (max-width: 1024px) {
  .banner-heading {
      font-size: 3.4rem; 
  }

  .banner-paragraph {
      font-size: 0.9rem; 
  }

  .book-demo-button {
      font-size: 1.2rem; 
  }

  .trusted-by-heading {
      font-size: 1rem; 
  }

  .trusted-by-logos .logo {
      width: 90px; 
  }
}

@media (max-width: 768px) {
  .banner-heading {
      font-size: 2rem; 
  }

  .banner-paragraph {
      font-size: 0.9rem; 
  }

  .book-demo-button {
      font-size: 1rem; 
  }

}

@media (max-width: 480px) {
  .banner-icon {
      width: 60px; 
  }

  .banner-heading {
      font-size: 1.8rem; 
  }

  .banner-paragraph {
      font-size: 0.8rem; 
  }

  .book-demo-button {
      padding: 10px 20px;
  }

  .banner {
    margin-bottom: 10px;

}
.dashboard-image {
  height: auto;
}

}
