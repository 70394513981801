* {
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 150px;
    background-color: white;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 999;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
  
  .logo img {
    height: 40px;
  }
  
  .sections {
    display: flex;
    gap: 60px;
    flex-grow: 1;
    justify-content: center;
    margin-left: 60px;
}
  
  .sections a, .sections button{
    color: #0D5C9C;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    background: none;
  }
 
  .auth-buttons {
    display: flex;
    gap: 10px;
  }
  
  .auth-buttons button {
    padding: 7px 14px;
    cursor: pointer;
    border-radius: 12px;
    font-size: 1rem;
  }

  .login-btn {
    background-color: white;
    color: #0D5C9C !important;
    border: none;
  }

  .signup-btn {
    background-color: white;
    border: 1px solid #0D5C9C !important;
    color: #0D5C9C !important;
  }

  /* Hamburger Menu Styles */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger div {
    width: 25px;
    height: 5px;
    background-color: #0D5C9C;
    margin: 2px 0;
}
  
  .navbar-menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #333;
    border: 1px solid #555;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
  }
  
  .navbar-menu.open {
    display: flex;
    flex-direction: column;
    z-index: 111;
    background-color: #001F63;
    border-radius: 12px;
}

  .navbar-menu .sections, 
  .navbar-menu .auth-buttons {
    display: flex;
    flex-direction: column;
  }
  
  .navbar-menu a, .navbar-menu button {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
    text-align: center;
  }
  
  .navbar-menu .auth-buttons button {
    margin: 5px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .sections {
      display: none;
      gap: 8px;
      margin-left: 0;
    }
  
    .auth-buttons {
      display: none;
      gap: 8px;
    }
  
    .hamburger {
      display: flex;
    }
    .navbar{
      padding: 20px 50px;
    }
  }
  
  @media (max-width: 768px) {
    .navbar-menu {
      top: 50px;
    }
    .navbar{
      padding: 20px 20px;
    }
  }
  @media (max-width: 480px){
    .navbar-menu a, .sections button {
      padding: 4px;
    }
    .sections a, .sections button{
      font-size: 0.8rem;
    }
    .auth-buttons button {
      padding: 10px;
      font-size: 0.9rem;
  }
  .auth-buttons {
    gap: 0px;
  }
  .navbar-menu {
    width: 150px;
  }
  }