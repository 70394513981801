.savings-container {
    max-width: 1000px;
    margin: 0 auto 120px;
    padding: 0px 20px 20px;
    margin-bottom: 90px;
  }
  
  .savings-background {
    text-align: center;
    padding: 20px;
    background-image: url('../../assets/SavingsSection/SavingsBg.svg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-attachment: fixed; 
  }

  .heading-small {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .heading-large {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.3;
  }

  .savings-card-value {
    font-size: 3rem;
    text-align: center;
    margin-top: 50px;
  }
  
  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .card-item {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    padding: 40px;
    border-radius: 22px;
    justify-content: space-between;
}

.card-item-monthly-spending{
    flex: 1 1;
    min-width: 250px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    padding: 40px;
    border-radius: 22px;
}

  .card-item-large {
    flex: 1 1 100%;
    padding: 40px;
    border-radius: 22px;
}

  .card-image {
    width: 100%;
    height: auto;
  }
  .resource-card-image{
    width: 100%;
    height: auto;
    max-width: 100%;
    align-self: center;
  }

  .card-title {
    padding: 10px;
    font-size: 1.2em;
    color: #333;
    background: #fff;
    text-align: left;
  }
  
  /* Responsive design adjustments */
  @media (max-width:820px){
    .resource-card-image {
      width: 52%;
  }
  .savings-card-value {
    margin-top: 66px;
  }
  }

  @media (max-width: 768px) {
    .card-wrapper {
      align-items: center;
    }
    .card-item {
      width: 50%;
    }
    .card-item-monthly-spending{
      min-height: 314px;
    }
    .resource-card-image {
      max-height: 210px;
    }
    .heading-large{
      font-size: 1.8rem;
    }
  }

  @media (max-width: 480px) {
    .card-item-large .card-image {
      content: url('../../assets/SavingsSection/SavingsImgSmallScreen.svg');
    }
    .heading-small {
        font-size: 0.9rem; 
    }
    .heading-large{
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .card-item {
      max-width: 100%;
      padding: 20px;
      max-height: 300px;
    }
    .card-title {
      font-size: 0.8rem;
      }
      .savings-card-value {
          font-size: 3.3rem;
          text-align: center;
          margin-top: 55px;
      }
      .savings-background {
        background: none;
      }
      .savings-container {
        margin-bottom: 40px;
      }
      .card-item-monthly-spending{
        min-height: 260px;
        padding: 20px;
      }
      .banner-content {
        z-index: 2;
        margin: 30px 0 0;
    }
}
