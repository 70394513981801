.automation-section {
    position: relative; /* Make sure the section is positioned relatively */
    padding: 20px 40px;
    text-align: center;
    color: #fff;
    margin-bottom: 90px;
    overflow: hidden; /* Hide any overflow from pseudo-element */
}

.automation-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/AutomationSection/AutomationBg.svg') no-repeat center center / cover;
    opacity: 0.6; /* Set opacity of the background image */
    z-index: -1; /* Place background behind the content */
}

.automation-section-content {
    position: relative; /* Ensure content stays above the pseudo-element */
    z-index: 1; /* Make sure content is above the background */
}


.automation-header {
    margin-bottom: 40px;
}

.small-heading {
    font-size: 1rem;
    color: black;
    margin-bottom: 20px;
    font-weight: 300;
}

.large-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.3;
}

.automation-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    color: #EBF3FF;
}

.automation-card-row {
    display: flex;
    justify-content: center;
    gap: 60px;
    width: 100%;
    margin: 0 auto; 
    flex-wrap: wrap;
}

.bottom-row {
    display: flex;
    justify-content: center;
    gap: 60px;
}

.automation-card {
    background: #eff6ff;
    border-radius: 22px;
    box-shadow: 0 4px 16px #ececff59;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px;
    width: 350px;
    border: 1px solid;
}
.automation-card:hover{
    background: #001F63;
    transform: scale(1.03)
}
.automation-card-heading.hovered,
.card-description.hovered {
    color: #fff; /* Change text color to white on hover */
}

.card-icon {
    width: 40px;
    transition: opacity 0.3s ease; /* Optional: smooth icon transition */
}

.automation-card:hover .card-icon {
    opacity: 1; /* Fade out the inactive icon */
    transition: opacity 0.3s ease; 
}

.automation-card:hover .card-icon.active {
    opacity: 1; /* Fade in the active icon */
    transition: opacity 0.3s ease; 
}

.card-header {
    display: flex;
    align-items: center;
    gap: 15px; 
    margin-bottom: 20px; 
}

.card-icon {
    width: 40px;
}

.automation-card-heading {
    font-size: 1rem;
    color: #333;
    text-align: left;

}

.card-description {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.3;
    text-align: left;
}



/* Responsive Design */
@media (max-width: 1024px) {
    .automation-card-row {
        align-items: center;
        gap: 60px; 
    }

    .top-row {
        margin-bottom: 0; 
    }

    .automation-card .automation-card-active{
        width: 100%;
        max-width: 300px; 
    }

    .card-header {
        align-items: center;
        text-align: center;
    }

    .card-icon {
        width: 50px;
    }
}

@media (max-width: 768px) {
    .small-heading {
        font-size: 1.2rem; 
    }

    .large-heading {
        font-size: 1.8rem; 
    }

    .card-icon {
        width: 40px; 
    }

    .automation-card-heading .automation-card-heading-active{
        font-size: 1.1rem;
    }

    .card-description {
        font-size: 0.8rem; 
    }
}

@media (max-width: 480px) {
    .small-heading {
        font-size: 0.9rem; 
    }
    
    .large-heading{
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .automation-section::before {
        background: none;
    }
    .automation-card-row {
        gap: 30px;
    }
    .automation-cards {
        gap: 30px;
    }
    .automation-card{
        padding: 18px;
    }
  }