.cta-section {
    position: relative;
    background: url('../../assets/CTASection/cta-bg-img.svg') no-repeat center center;
    background-size: cover;
    padding: 60px 20px; 
    text-align: center;
    color: #fff; 
}

.cta-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    border-radius: 22px;
    margin-bottom: 120px;
}

.cta-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px; 
}

.cta-heading {
    font-size: 3rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 150%;
}

.cta-paragraph {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.5;
    color: #333;
}

@media(max-width: 768px){
    .cta-container {
        margin: 0 15px 120px;
        padding: 22px 20px;
    }
    .cta-heading {
        font-size: 2.2rem;
    }
}
@media (max-width: 480px) {
    .cta-paragraph {
        font-size: 0.9rem; 
    }
    
    .cta-heading {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .cta-section {
        position: relative;
        background: none;
        background-color: #F4F0FF;
        background-size: cover;
        padding: 50px 20px;
        text-align: center;
        color: #F4F0FF;
    }
}
