/* Trusted By Section */
.trusted-by {
    text-align: center;
    padding: 60px 20px 20px;
  }
  
  .trusted-by-heading {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .trusted-by-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .trusted-by-logos .logo {
    width: 100px;
    height: auto;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .trusted-by-logos .logo:hover {
    opacity: 1;
  }
  

  @media (max-width: 768px) {
    .trusted-by-heading {
        font-size: 1rem; 
    }
  
    .trusted-by-logos {
        gap: 10px; 
    }
  
    .trusted-by-logos .logo {
        width: 80px; 
    }
  }

  @media (max-width: 480px) {
    .trusted-by-heading {
        font-size: 0.8rem; 
    }
  
    .trusted-by-logos {
       flex-wrap: wrap; 
        align-items: center;
    }
  
    .trusted-by-logos .logo {
        width: 60px; 
        margin-bottom: 10px; 
    }
  }