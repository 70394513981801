.demo-section {
    padding: 40px 20px;
    text-align: center;
    background: url('../../assets/DemoSection/DemoBg.svg') no-repeat center center/cover; 
    color: #333;
    margin-bottom: 90px;
}

.demo-header {
    margin-bottom: 40px;
}

.demo-small-heading {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.demo-main-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.3;
}

.demo-image-container {
    display: flex;
    justify-content: center;
}

.demo-image {
    width: 100%;
    max-width: 1000px; 
    height: auto; 
    cursor: pointer; 
}

/* Styles for iframe modal */
.iframe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(8px); 
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease, pointer-events 0.3s ease;
}

.iframe-overlay.show {
    opacity: 1;
    pointer-events: all;
}

.iframe-container {
    position: relative;
    width: 90%;
    max-width: 1200px;
    height: 80%;
    max-height: 80%;
}

.iframe-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.iframe-close-btn:hover {
    background: #f5f5f5;
}

.iframe-container iframe {
    width: 100%;
    height: 100%;
    border: 13px solid #42a3e8;
}
.demo-image-container {
    display: flex;
    justify-content: center;
}

.demo-image {
    width: 100%;
    max-width: 1000px; 
    height: auto; 
    cursor: pointer; 
}

.embedded-video {
    width: 100%;
    max-width: 1000px;
    height: 560px; 
    border: 8px solid #0D0C46; 
    border-radius: 16px; 
    box-shadow: 0 0 0 8px #D3DEFF; 
}

/* Responsive Design for iframe */
@media (max-width: 1024px) {
    .iframe-container {
        height: 70%; 
    }
}

@media (max-width: 768px) {
    .demo-main-heading {
        font-size: 1.8rem;
    }
    .iframe-container {
        height: 80%; 
    }
    .embedded-video {
        height: 400px; 
    }
}

@media (max-width: 576px) {
    .iframe-container {
        height: 70%; 
    }

    .iframe-close-btn {
        width: 25px;
        height: 25px;
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .demo-small-heading {
        font-size: 0.9rem; 
    }
    
    .demo-main-heading{
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .demo-section {
        margin-bottom: 0px;
    }
    .iframe-container {
        height: 50%; 
    }
    .embedded-video {
        height: 280px; 
    }
}
