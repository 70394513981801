.faq-section {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 40px 20px;
    text-align: center;
    margin-bottom: 90px;
  }
  
  .faq-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .faq-section button {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .faq-items {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .faq-item {
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 10px;
    transition: all 0.3s ease;
  }
  
  .faq-item.active {
    background-color: #edf1ff;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #555;
  }
  
  .faq-question h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  
  .faq-icon {
    font-size: 24px;
    font-weight: bold;
    transition: transform 0.3s ease;
  }
  
  .faq-answer {
    font-size: 16px;
    color: #666;
    margin-top: 15px;
    text-align: left;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .faq-section {
      padding: 20px 10px;
    }
  
    .faq-section h2 {
      font-size: 1.8rem;
    }
    .faq-question h3 {
      font-size: 1.2rem;
      text-align: left;
    }
  
    .faq-answer {
      font-size: 14px;
      text-align: left;
    }
  }
  @media (max-width:480px){
    .faq-section h2 {
      font-size: 1.4rem;
    }
    .faq-question h3 {
      font-size: 0.9rem;
      text-align: left;
    }
  }
  