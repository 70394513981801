.enterprise-section {
  padding: 0 20px;
  text-align: center;
  margin-bottom: 110px;
  }
  
  .enterprise-header h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
  
  .enterprise-header p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
  }
  
  .enterprise-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 40px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px; 
    margin: 0 auto;
  }
  
  .enterprise-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .enterprise-card:hover {
    transform: translateY(-5px);
  }
  
  .enterprise-card-icon {
    max-width: 100%; 
    margin-bottom: 20px;
  }
  
  .enterprise-card h3 {
    font-size: 1.3rem;
    color: #333;
    line-height: 1.3;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .enterprise-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.3;
    text-align: left;
  }
  
  .enterprise-header button{
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1024px) {
    .enterprise-header h1 {
      font-size: 2rem;
    }
  
    .enterprise-header p {
      font-size: 1.1rem;
    }
  
    .enterprise-card {
      padding: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .enterprise-header h1 {
      font-size: 1.8rem;
    }
  
    .enterprise-header p {
      font-size: 1rem;
    }
  
    .card-icon {
      max-width: 100px; 
    }
  }
  
  @media (max-width: 480px) {
    .enterprise-header h1 {
      font-size: 1.6rem;
    }
  
    .enterprise-header p {
      font-size: 0.9rem;
    }
  
    .enterprise-cards {
      grid-template-columns: 1fr; 
    }
  
    .enterprise-card {
      padding: 15px;
    }
    .enterprise-card h3{
      font-size: 1rem;
    }
    .enterprise-card p{
      font-size: 0.8rem;
    }
  }
  