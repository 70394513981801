.solution-section {
    padding: 0 40px 20px;
    text-align: center;
    margin-bottom: 90px;
}

.solution-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.solution-icon {
    width: 40px;
    margin-right: 10px;
}

.solution-subheading {
    font-size: 0.9rem;
    color: #5B5B5B;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    padding: 4px 20px;
    border-radius: 22px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.solution-main-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.3;
}

.solution-cards {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
}

.solution-card {
    background: linear-gradient(to bottom, #FFFFFF 0%, #F4F0FF 19%, #E8F7FF 61%);
    border-radius: 22px;
    box-shadow: 0 4px 16px #ececff59;
    padding: 34px;
    max-width: 450px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 350px;
}

.solution-card-image {
    width: 60px; 
    margin-bottom: 20px;
}

.solution-card-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    gap: 15px;
}

.solution-card-header {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.solution-card-icon {
    width: 30px; 
}

.solution-card-heading {
    font-size: 1.5rem;
    color: #333;
    line-height: 1.3;
}

.solution-card-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.3;
}

.solution-card-bottom-image {
    width: 100%; 
    margin-top: 15px;
}
.solution-card-bottom-image {
    width: 100%; 
    margin-top: 15px;
    transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.solution-card-bottom-image:hover {
    transform: scale(1.1); /* Zoom effect on hover */
}

@media (max-width: 1024px) {

    .solution-card {
        width: 100%;
        max-width: 450px;
    }

    .solution-card-content {
        text-align: center; 
    }
}

@media (max-width: 768px) {
    .solution-cards {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .solution-main-heading {
        font-size: 1.8rem; 
    }

    .solution-card-icon {
        width: 25px; 
    }

    .solution-card-heading {
        font-size: 1.1rem; 
    }

    .solution-card-description {
        font-size: 0.8rem; 
    }
}
@media (max-width: 480px) {
    .solution-subheading {
        font-size: 0.9rem; 
    }
    .solution-card{
        padding: 15px;
    }
    .solution-card-description{
        text-align: left;
    }
    .solution-main-heading{
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    .solution-section {
        margin-bottom: 0px;
    }
}