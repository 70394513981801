* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.problem-section {
  text-align: center;
  margin-bottom: 90px;
}

.problem-heading {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 50px;
  line-height: 1.3;
}

.problem-card {
  background: linear-gradient(180deg, white, #F4F8FF);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.problem-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-logo {
  width: 40px;
  margin-bottom: 10px;
}

.card-heading {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
}

.card-content {
  font-size: 0.9rem;
  color: #7a7a7a;
  line-height: 150%;
}

.full-card {
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
}

.card-row {
  display: flex;
  gap: 20px;
  position: relative;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
}

.problem-cards {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-row:nth-child(1) {
  animation: scroll-left 30s linear infinite;
}

.card-row:nth-child(2) {
  animation: scroll-right 30s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20%);
  }
}

@media (max-width: 1023px) {
  .problem-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .card-row {
    display: flex;
    gap: 20px;
    overflow: visible;
    position: static;
    animation: none; 
  }

  .problem-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    box-sizing: border-box;
  }

  .left, .right {
    margin-left: 0;
    margin-right: 0;
  }
  .card-row {
    animation: none !important; 
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }

  .problem-card {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-bottom: 20px;
  }

  .problem-heading {
    font-size: 1.8rem;
  }

  .hidden-on-mobile {
    display: none; 
  }
}

@media (max-width: 768px) {
  .problem-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    overflow: visible;
  }
}

@media (max-width: 480px) {
  .problem-heading {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .problem-section {
    margin-bottom: 0px;
  }

  .problem-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
